// Demo page
import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Card, Col, Row, Spinner, InputGroup } from 'react-bootstrap'
import { AiFillCheckCircle } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoChevronBack } from "react-icons/io5";
import Header from '../../components/header/Header'
import Footer from '../footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import * as SourcesApi from "../../api/SourcesApi"
import * as IndustryApi from "../../api/industryApi"
import * as DemoApi from "../../api/demoApi"
import * as CountryApi from "../../api/countryApi"
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from "../../handleApiErrors";
import { convertDateForApi } from "../../HelperFile";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import { MdErrorOutline } from "react-icons/md";
import { validateForm } from "../../handleValidation";

const defaultDemoData = {
  Name: '',
  Email: '',
  Phone: '',
  CompanyName: '',
  // Country: '',
  Sources: '',
  Industry: '',
  PreferredDate: '',
  PromoCode: ''
};
const validationRules = {
  Name: { required: true },
  CompanyName: { required: true },
  CompanySize: { required: true },
  EmailId: { required: true, isEmail: true },
  // PreferredDate: { required: true },
  PhoneNumber: { isPhoneNumber: true, required: true },
  SourceId: { required: true },
  IndustryId: { required: true },
};
function Demo() {
  const abortConstants = useSignalAbort()
  const navigate = useNavigate(); // Initialize the navigate function
  const defaultCountry = useSelector(state => state.geo.geoData)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [DemoData, setDemoData] = useState(defaultDemoData)
  const [Sources, setSources] = useState()
  const [Sourcesselect, setSourcesselect] = useState("")
  const [Country, setCountry] = useState()
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [Industry, setIndustry] = useState()
  const [Industryselect, setIndustryselect] = useState("")
  const [CompanySizeselect, setCompanySizeselect] = useState("")
  const [isLoading, setIsLoading] = useState({})
  const [issubmit, setIsSubmit] = useState(false)
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormerrors] = useState({})

  useEffect(() => {
    const country = Country?.find(item => item.countryCode === defaultCountry.country_code2)
    // console.log(defaultCountry);
    console.log(country);
    setSelectedCountry(country)
  }, [Country])

  //Sources
  const getSource = async (abortToken) => {
    try {
      const result = await SourcesApi.getSources(abortToken);
      // console.log(result);
      setSources(result?.data?.Source);
      // setSourcesselect(result?.data?.Source[0]?.SourcesId)
    }
    catch (error) {
      console.log(error);
      // handleApiErrors(error, 'Sources', 'sources_response', 'sources_timeout', 'sources_request', setIsLoading)
    }
  };

  //Demo
  const Demonow = async (data, abortToken) => {
    console.log(data);
    try {
      const result = await DemoApi.Demo(data, abortToken);
      console.log(result);
      if (result) {
        setIsSubmit(false);
        setDemoData(defaultDemoData)
        setSourcesselect("")
        setIndustryselect("")
        setCompanySizeselect("")
      }
      setValidated(false)
      setFormSubmitted(true)
    }
    catch (error) {
      setIsSubmit(false);
      // console.error(error);
      handleApiErrors(error, "Demonow", "Demonow_response", "Demonow_timeout", "Demonow_request", setIsLoading);
    }
  };

  ///getCountry
  const getCountry = async (abortToken) => {
    try {
      const result = await CountryApi.getCountry(abortToken);
      console.log(result?.data?.Country);
      setCountry(result?.data?.Country);
      // setCountryselect(result?.data?.Country[0].countryId, abortToken)
    } catch (error) {
      console.log(error);
      // handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
    }
  };

  //Industry
  const getIndustry = async (abortToken) => {
    try {
      const result = await IndustryApi.getIndustry(abortToken);
      // console.log(result);
      setIndustry(result?.data?.Industry);
      // setIndustryselect(result?.data?.Industry[0]?.IndustryId)
    }
    catch (error) {
      console.log(error);
      // handleApiErrors(error, 'Industry', 'Industry_response', 'Industry_timeout', 'Industry_request', setIsLoading)
    }
  };

  //For form validation
  const validate = (formData) => {
    const errors = {}
    // Validate phone number
    const isValidPhone = (phone) => {
      if (!/^[0-9]+$/.test(phone) || phone.length !== selectedCountry?.MobileNumberlen) {
        return false; // Invalid phone format
      }
      return true; // Valid phone format
    };

    // Validate each form field
    if (!formData.Name.trim()) {
      errors.Name = 'Name is required';
    }

    if (!formData.CompanyName.trim()) {
      errors.CompanyName = 'Company Name is required';
    }

    if (!formData.CompanySize.trim()) {
      errors.CompanySize = 'Company Size is required';
    }

    // if (!formData.PreferredDate.trim()) {
    //   errors.slot = 'Slot is required';
    // }

    if (!formData.SourceId) {
      errors.source = 'Source is required';
    }

    if (!formData.IndustryId) {
      errors.Industry = 'Industry is required';
    }

    if (!formData.EmailId.trim()) {
      errors.Email = 'Email is required';
    } else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.EmailId))) {
      errors.Email = 'Invalid email format';
    }

    if (!DemoData?.Phone.trim()) {
      errors.Phone = 'Phone number is required';
    } else if (!isValidPhone(DemoData?.Phone)) {
      errors.Phone = 'Invalid phone number';
    }

    console.log(errors);
    setFormerrors(errors)
    if (Object.keys(errors).length === 0) {
      return true;
    }
    else {
      // setIsSaving(false)
      return false;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    setIsSubmit(true);
    setFormerrors({})
    const formattedDate = DemoData.PreferredDate ? convertDateForApi(DemoData.PreferredDate) : ''
    const form = event.currentTarget; // The form that triggered the event
    let newData = {
      PhoneNumber: "+" + selectedCountry.CallingCode + DemoData.Phone,
      EmailId: DemoData.Email,
      CompanyName: DemoData.CompanyName,
      CompanySize: CompanySizeselect,
      PreferredDate: formattedDate,
      SourceId: Number(Sourcesselect),
      IndustryId: Number(Industryselect),
      CountryId: Number(selectedCountry.countryId),
      Name: DemoData.Name,
      Description: "",
      Website: "",
      AnnualRevenue: 0,
      LeadOwner: null,
      Status: 1,
      PromoCode: DemoData.PromoCode
    };
    console.log(newData);
    if (validate(newData)) {
      Demonow(newData, abortConstants.controllerConfig);
    } else {
      setIsSubmit(false);
    }

    // if (form.checkValidity() === false) {
    //   event.stopPropagation(); // Stops further event propagation if validation fails
    //   setIsSubmit(false);

    // } else {
    //   Demonow(newData, abortConstants.controllerConfig); // Call the function to submit the form data

    // }

    // setValidated(true); // Sets form as validated
  };
  const handleChange = (e) => {
    setDemoData({ ...DemoData, [e.target.name]: e.target.value })
    validateField(e.target.name, e.target.value);
  }
  const handleIndustry = (e) => {
    setIndustryselect(e.target.value);
    console.log(Industryselect);
  }
  const handleSources = (e) => {
    setSourcesselect(e.target.value);
    console.log(Sourcesselect);
  }
  const handleCountryselect = (country) => {
    if (country) {
      setSelectedCountry(country);
    }
  };

  const getAllData = useCallback((abortToken) => {
    getSource(abortToken);
    getIndustry(abortToken);
    getCountry(abortToken);
  }, []);

  useEffect(() => {
    getSource(abortConstants.controllerConfig);
    getIndustry(abortConstants.controllerConfig);
    getCountry(abortConstants.controllerConfig);

    // getAllData(abortConstants.controllerConfig);

    // return () => {
    //   // Cleanup logic if needed
    //   abortConstants.controller.abort();
    // };
  }, [getAllData]);

  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
      return true
    }
    else {
      return false
    }
  }
  const validateField = (fieldName, value) => {

    switch (fieldName) {
      case 'Name':
        if (value) {
          setFormerrors({ ...formErrors, Name: null })
        }
        break;
      case 'Phone':
        if (value) {
          setFormerrors({ ...formErrors, Phone: null })
        }
        break;
      case 'Email':
        if (value) {
          setFormerrors({ ...formErrors, Email: null })
        }
        break;
      case 'CompanyName':
        if (value) {
          setFormerrors({ ...formErrors, CompanyName: null })
        }
        break;

      default:
        break;
    }
  }
  return (
    <div>
      <div>
        <Header />
      </div>
      {!formSubmitted ? (
        <>
          <Container fluid>
{/* 
            <div className='back mt-5 common-icon' onClick={() => navigate('/')}>
              <IoChevronBack className='backicon ' />
              <span className='backtext'>Back</span>
            </div> */}

            <div className='mt-1 mb-5  text-center'>
              <div><span className='heading-black'>Experience the power of RCOR Intelliger.</span></div>
              <div className='mt-2'><span className='heading-black'>Schedule your personalized<span className='heading-blue'> Demo now!</span></span></div>
            </div>



            <div className="d-flex justify-content-center demo form-container form" >
              <Row className="d-flex justify-content-center w-100 p-md-5" >
                <Col xs={12} >
                  <Form
                    className=' p-2'
                    noValidate
                    // validated={validated}
                    onSubmit={handleSubmit} // Pass only one parameter
                  >
                    <Row>
                      <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Name <span className='asterik'>*</span> </Form.Label>
                          <Form.Control
                            className='textfields'
                            required
                            type="text"
                            maxLength={100}
                            name='Name'
                            placeholder="Name"
                            value={DemoData?.Name}
                            onChange={handleChange}
                          />
                          {formErrors.Name && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Name}</span>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6} className="pe-lg-3 ps-lg-5 mt-5 mt-md-0">
                        <Form.Group className='textgroup' controlId="floatingTextarea">
                          <Form.Label>Phone Number <span className='asterik'>*</span> </Form.Label>
                          <InputGroup>
                            <Dropdown style={{ height: '55px', marginTop: '18px' }}>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="textfields"
                                style={{
                                  // height: '100%',
                                  // width: '100%',
                                  backgroundColor: '#F6F6F6',
                                  color: 'black',
                                  border: 'none',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  paddingLeft: '10px',
                                  borderTopRightRadius: 0, borderBottomRightRadius: 0,
                                }}
                              >
                                {selectedCountry && (
                                  <>
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                                      alt={selectedCountry.countryName}
                                      className="country-flag"
                                      style={{ width: '26px', marginRight: '10px' }}
                                    />
                                    +{selectedCountry.CallingCode}
                                  </>
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {Country ? (
                                  <>
                                    {Country.length !== 0 ? (
                                      <>
                                        {Country.map((item) => (
                                          <Dropdown.Item
                                            key={item.countryName}
                                            name={item.countryName}
                                            value={item.countryId}
                                            onClick={() => handleCountryselect(item)}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                                              alt={item.countryName}
                                              className="country-flag"
                                              style={{ width: '20px', marginRight: '10px' }}
                                            />
                                            {item.countryName} (+{item.CallingCode})
                                          </Dropdown.Item>
                                        ))}
                                      </>
                                    ) : (
                                      <h4 style={{ textAlign: "center" }}>No data</h4>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {renderLoading("country") && (
                                      <h5 style={{ textAlign: "center" }}>No data</h5>
                                    )}
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              className='textfields'
                              required
                              type="phone"
                              pattern="[0-9]*"
                              inputMode="numeric"
                              aria-describedby="inputGroupPrepend"
                              maxLength={selectedCountry?.MobileNumberlen}
                              name='Phone'
                              placeholder="Phone number"
                              value={DemoData?.Phone}
                              onChange={handleChange}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </InputGroup>
                          {formErrors.Phone && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Phone}</span>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row >
                      <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3 mt-5">
                        <Form.Group className='textgroup' controlId="formEmail">
                          <Form.Label>Email address <span className='asterik'>*</span></Form.Label>
                          <Form.Control
                            className='textfields'
                            required
                            type="email"
                            maxLength={100}
                            name='Email' placeholder="Email"
                            value={DemoData?.Email} onChange={handleChange} />
                          {formErrors.Email && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Email}</span>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6} className="pe-lg-3 ps-lg-5 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Company Name <span className='asterik'>*</span></Form.Label>
                          <Form.Control
                            className='textfields'
                            required
                            type="text"
                            name='CompanyName'
                            maxLength={120}
                            placeholder="Company Name"
                            value={DemoData?.CompanyName}
                            onChange={handleChange} />
                          {formErrors.CompanyName && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.CompanyName}</span>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row >
                      <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Industry </Form.Label>
                          <Form.Select
                            className='textfields'
                            required
                            name="Industry"
                            value={Industryselect}
                            onChange={handleIndustry}
                          >
                            <option value="" disabled>Select</option>
                            {Industry ? (
                              <>
                                {Industry.length !== 0 ? (
                                  <>
                                    {Industry?.map((item) => (
                                      <option
                                        key={item.IndustryId}
                                        // eventKey={item.IndustryName}
                                        name={item.IndustryName}
                                        value={item.IndustryId}
                                      >
                                        {item.IndustryName}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  <span style={{ textAlign: "center" }}>No data</span>
                                )}
                              </>
                            ) : (
                              <>
                                {renderLoading("sources") && (
                                  <span style={{ textAlign: "center" }}>No data</span>
                                )}
                              </>
                            )}
                          </Form.Select>
                          {formErrors.Industry && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Industry}</span>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6} className="pe-lg-3 ps-lg-5 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label> Number of Employees</Form.Label>
                          <Form.Select required className='textfields' value={CompanySizeselect} onChange={(e) => setCompanySizeselect(e.target.value)}>
                            <option value="" disabled>Select</option>
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-200">51-200</option>
                            <option value="201-500">201-500</option>
                            <option value="501+">Above 500</option>
                          </Form.Select>
                          {formErrors.CompanySize && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.CompanySize}</span>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row >
                      {/* <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Book a slot <span className='asterik'>*</span></Form.Label>
                          <div className="custom-date-picker">
                            <Form.Control
                              className='textfields'
                              type="date"
                              required
                              name="PreferredDate"
                              min={new Date().toISOString().split('T')[0]}
                              value={DemoData?.PreferredDate}
                              placeholder="Date and Time"
                              onChange={handleChange}
                            />
                            <img src={process.env.PUBLIC_URL + `/assets/images/datetimeIcon.png`} alt="Date Time Icon" className="custom-icon" height={22} width={22} />
                          </div>
                          {formErrors.slot && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.slot}</span>}
                        </Form.Group>
                      </Col> */}
                      <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>How did you hear about us? <span className='asterik'>*</span></Form.Label>
                          <Form.Select
                            className='textfields'
                            required
                            name="Sources"
                            value={Sourcesselect}
                            onChange={handleSources}
                          >
                            <option value="" disabled>Select</option>
                            {Sources ? (
                              <>
                                {Sources.length !== 0 ? (
                                  <>
                                    {Sources?.map((item) => (
                                      <option
                                        key={item.SourceId}
                                        // eventKey={item.SourcesName}
                                        name={item.SourceName}
                                        value={item.SourceId}
                                      >
                                        {item.SourceName}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  <span style={{ textAlign: "center" }}>No data</span>
                                )}
                              </>
                            ) : (
                              <>
                                {renderLoading("sources") && (
                                  <span style={{ textAlign: "center" }}>No data</span>
                                )}
                              </>
                            )}
                          </Form.Select>
                          {formErrors.source && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.source}</span>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} lg={6} className="pe-lg-3 ps-lg-5 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Have a Promo Code? <span className="demo form-subtext">(Optional)</span></Form.Label>
                          <Form.Control
                            className='textfields'
                            type="text"
                            // maxLength={100}
                            name='PromoCode'
                            placeholder="Promo Code"
                            value={DemoData?.PromoCode}
                            onChange={handleChange}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please provide Name .
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row >
                      <Col xs={12} lg={6} className="pe-lg-5 ps-lg-3 mt-5">
                        <Form.Group className='textgroup' controlId="formName">
                          <Form.Label>Have a Promo Code? <span className="demo form-subtext">(Optional)</span></Form.Label>
                          <Form.Control
                            className='textfields'
                            type="text"
                            // maxLength={100}
                            name='PromoCode'
                            placeholder="Promo Code"
                            value={DemoData?.PromoCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row className="mt-4">
                      <Col xs={12} className='headline home-colC'>
                        {!issubmit && <Button type='submit' className='button submit-colors mt-4 mb-4'>&nbsp;&nbsp;&nbsp;&nbsp;SUBMIT&nbsp;&nbsp;&nbsp;&nbsp;</Button>}
                        {issubmit &&
                          <Button className='button button-disable d-flex justify-content-center align-items-center mt-4 mb-4' >
                            <span className='me-2'> Submitting....</span>
                            <Spinner animation="border" variant="light" size='sm' />
                          </Button>}
                      </Col>
                    </Row>


                  </Form>
                </Col>
              </Row>

            </div>
            <div className='empty-div'> </div>
          </Container>

        </>
      ) : (
        <>





          {/* submit window */}
          <Container>
            {/* <div className='back mt-5' onClick={() => setFormSubmitted(false)}>
              <IoChevronBack className='backicon'  />
              <span className='backtext  '>Back</span>
            </div> */}
            <div className='confirm demo-div'>
              <Card className='confirm demo-card  p-5' style={{ backgroundColor: '#F4FFFD' }}>
                <div className=' text-center  '>
                  <AiFillCheckCircle className='tick-icon' />
                </div>
                <div className='text-center mt-4'>
                  <h6 className='tick-heading'>Thank You for requesting a demo with us !</h6>
                </div>

                <div className='text-center mt-4'>
                  <h6 className='fw-normal'>Our sales person will get in touch with you shortly</h6>
                </div>
              <div className='text-center'>
              <Button className='button submit-colors mt-4' onClick={()=> window.location.href = 'https://www.rcor.co/rcorintelliger'}>Go Back Home</Button>
              </div>
                <div className='thank-main text-center mt-3'>
                  <div><span ><span><AiOutlineQuestionCircle className='question-icon' /></span>  If you have any questions or need assistance, please check out
                    <span className='tick-heading'><Link to='https://www.rcor.co/casestudies' className='tick-heading'> Blog Page</Link></span></span></div>
                  <div className=''><span>or send email to<span className='support'>  support@rcor.co</span></span></div>
                </div>

              </Card>
            </div>

            <div className='empty-div'> </div>

          </Container>

        </>
      )}
      {/* </Container> */}

      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Demo




{/* <Col xs={12} md={12} lg={12} xl={12} sm={12}>
<Row className="headline home-colC">
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5'>
    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Name <span className='asterik'>*</span> </Form.Label>
      <Form.Control
        className='textfields'
        required
        type="text"
        maxLength={100}
        name='Name'
        placeholder="Name"
        value={DemoData?.Name}
        onChange={handleChange}
      />
      {formErrors.Name && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Name}</span>}
    </Form.Group>
  </Col>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5'>
    <Form.Group className='textgroup' controlId="floatingTextarea">
      <Form.Label>Phone Number <span className='asterik'>*</span> </Form.Label>
      <InputGroup>
        <Dropdown style={{ height: '55px', marginTop: '18px' }}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="textfields"
            style={{
              // height: '100%',
              // width: '100%',
              backgroundColor: '#F6F6F6',
              color: 'black',
              border: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '10px',
              borderTopRightRadius: 0, borderBottomRightRadius: 0,
            }}
          >
            {selectedCountry && (
              <>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                  alt={selectedCountry.countryName}
                  className="country-flag"
                  style={{ width: '26px', marginRight: '10px' }}
                />
                +{selectedCountry.CallingCode}
              </>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Country ? (
              <>
                {Country.length !== 0 ? (
                  <>
                    {Country.map((item) => (
                      <Dropdown.Item
                        key={item.countryName}
                        name={item.countryName}
                        value={item.countryId}
                        onClick={() => handleCountryselect(item)}
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                          alt={item.countryName}
                          className="country-flag"
                          style={{ width: '20px', marginRight: '10px' }}
                        />
                        {item.countryName} (+{item.CallingCode})
                      </Dropdown.Item>
                    ))}
                  </>
                ) : (
                  <h4 style={{ textAlign: "center" }}>No data</h4>
                )}
              </>
            ) : (
              <>
                {renderLoading("country") && (
                  <h5 style={{ textAlign: "center" }}>No data</h5>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Control
          className='textfields'
          required
          type="phone"
          pattern="[0-9]*"
          inputMode="numeric"
          aria-describedby="inputGroupPrepend"
          maxLength={selectedCountry?.MobileNumberlen}
          name='Phone'
          placeholder="Phone number"
          value={DemoData?.Phone}
          onChange={handleChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </InputGroup>
      {formErrors.Phone && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Phone}</span>}
    </Form.Group>
  </Col>
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>
    <Form.Group className='textgroup' controlId="formEmail">
      <Form.Label>Email address <span className='asterik'>*</span></Form.Label>
      <Form.Control
        className='textfields'
        required
        type="email"
        maxLength={100}
        name='Email' placeholder="Email"
        value={DemoData?.Email} onChange={handleChange} />
      {formErrors.Email && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Email}</span>}
    </Form.Group>
  </Col>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>
    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Company Name <span className='asterik'>*</span></Form.Label>
      <Form.Control
        className='textfields'
        required
        type="text"
        name='CompanyName'
        maxLength={120}
        placeholder="Company Name"
        value={DemoData?.CompanyName}
        onChange={handleChange} />
      {formErrors.CompanyName && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.CompanyName}</span>}
    </Form.Group>
  </Col>
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>
    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Industry </Form.Label>
      <Form.Select
        className='textfields'
        required
        name="Industry"
        value={Industryselect}
        onChange={handleIndustry}
      >
        <option value="" disabled>Select</option>
        {Industry ? (
          <>
            {Industry.length !== 0 ? (
              <>
                {Industry?.map((item) => (
                  <option
                    key={item.IndustryId}
                    // eventKey={item.IndustryName}
                    name={item.IndustryName}
                    value={item.IndustryId}
                  >
                    {item.IndustryName}
                  </option>
                ))}
              </>
            ) : (
              <span style={{ textAlign: "center" }}>No data</span>
            )}
          </>
        ) : (
          <>
            {renderLoading("sources") && (
              <span style={{ textAlign: "center" }}>No data</span>
            )}
          </>
        )}
      </Form.Select>
      {formErrors.Industry && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.Industry}</span>}
    </Form.Group>
  </Col>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>

    <Form.Group className='textgroup' controlId="formName">
      <Form.Label> Number of Employees</Form.Label>
      <Form.Select required className='textfields' value={CompanySizeselect} onChange={(e) => setCompanySizeselect(e.target.value)}>
        <option value="" disabled>Select</option>
        <option value="1-10">1-10</option>
        <option value="11-50">11-50</option>
        <option value="51-200">51-200</option>
        <option value="201-500">201-500</option>
        <option value="501+">Above 500</option>
      </Form.Select>
      {formErrors.CompanySize && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.CompanySize}</span>}
    </Form.Group>
  </Col>
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5'>
    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Book a slot <span className='asterik'>*</span></Form.Label>
      <div className="custom-date-picker">
        <Form.Control
          className='textfields'
          type="date"
          required
          name="PreferredDate"
          min={new Date().toISOString().split('T')[0]}
          value={DemoData?.PreferredDate}
          placeholder="Date and Time"
          onChange={handleChange}
        />
        <img src={process.env.PUBLIC_URL + `/assets/images/datetimeIcon.png`} alt="Date Time Icon" className="custom-icon" height={22} width={22} />
      </div>
      {formErrors.slot && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.slot}</span>}
    </Form.Group>
  </Col>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>

    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Referral <span className='asterik'>*</span></Form.Label>
      <Form.Select
        className='textfields'
        required
        name="Sources"
        value={Sourcesselect}
        onChange={handleSources}
      >
        <option value="" disabled>Select</option>
        {Sources ? (
          <>
            {Sources.length !== 0 ? (
              <>
                {Sources?.map((item) => (
                  <option
                    key={item.SourceId}
                    // eventKey={item.SourcesName}
                    name={item.SourceName}
                    value={item.SourceId}
                  >
                    {item.SourceName}
                  </option>
                ))}
              </>
            ) : (
              <span style={{ textAlign: "center" }}>No data</span>
            )}
          </>
        ) : (
          <>
            {renderLoading("sources") && (
              <span style={{ textAlign: "center" }}>No data</span>
            )}
          </>
        )}
      </Form.Select>
      {formErrors.source && <span className="form-error span-text"><MdErrorOutline /> &nbsp;{formErrors.source}</span>}
    </Form.Group>
  </Col>
  
  <Col xs={12} md={5} lg={5} xl={5} sm={12} className='mb-5  '>
    <Form.Group className='textgroup' controlId="formName">
      <Form.Label>Have a Promo Code? <span className="demo form-subtext">(Optional)</span></Form.Label>
      <Form.Control
        className='textfields'
        type="text"
        // maxLength={100}
        name='PromoCode'
        placeholder="Promo Code"
        value={DemoData?.PromoCode}
        onChange={handleChange}
      />

    </Form.Group>
  </Col>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

</Row>
</Col>

<Col xs={12} md={12} lg={12} xl={12} sm={12} className='headline home-colC'>
{!issubmit && <Button type='submit' className='button submit-colors mt-4 mb-4'>&nbsp;&nbsp;&nbsp;&nbsp;SUBMIT&nbsp;&nbsp;&nbsp;&nbsp;</Button>}
{issubmit &&
  <Button className='button button-disable d-flex justify-content-center align-items-center mt-4 mb-4' >
    <span className='me-2'> Submitting....</span>
    <Spinner animation="border" variant="light" size='sm' />
  </Button>}
</Col> */}