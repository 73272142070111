import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all workspaces
export const getWorkspaces = (abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/WorkSpaces`, config)
}

//function for deleting a workspace
export const deleteWorkspace = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.delete(`/WorkSpace/${id}`,config)
}

//function for sync worksapce
export const syncZoho = (abortToken) => {
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
    };
    return api.get(`/Zoho/GetDashboard`, config)
}

//function for getting a specific dashboard
export const getDashboards = (id, abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/Dashboard/${id}`, config)
}

//function for delete a dashboard
export const deleteDashboard = (id, abortToken) => {
  console.log("dashbord delete");
  
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.delete(`/Dashboard/${id}`,config)
  }

//function for update a dashboard
export const updateDashboard = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/Dashboard/${id}`, data, config)
}

//function for update dashboard order
export const updateDashboardOrder = (data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 60000, // Set the timeout for this specific request
  };
  return api.put(`/Dashboard/`, data, config)
}

  //function for assigning a customer
export const assignCustomerToWorkspace = (data, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.post(`/AssignWorkSpaces`, data, config)
  }
  