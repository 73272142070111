
import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import CrudActions from '../../components/CrudActions';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { deleteLead, getLead } from '../../api/leadsApi';
import { useSelector } from 'react-redux';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';
import AutoSearch from '../../components/AutoSearch';
import { deleteDashboard, deleteWorkspace, getDashboards, getWorkspaces, syncZoho, updateDashboard } from '../../api/workspaceApi';
import { TbDotsVertical } from 'react-icons/tb';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import AssignCustomer from './AssignCustomer';
import { toast } from 'react-toastify';
import SetOrder from './SetOrder';

const initialFilterValues = {
    Name: '',
    // CompanyName: '',
    // IndustryName: '',
    // PhoneNumber: '',
    // EmailId: '',
    Date: '',
    // SourceName: '',
    Status: ''
}
function Workspace() {
    const abortConstants = useSignalAbort();
    const navigate = useNavigate();
    const user = useSelector(state => state.adminUser);
    const menu = useSelector(state => state.userMenu);
    const [workspaces, setWorkspaces] = useState();
    const [dashboards, setDashboards] = useState([]);
    const [isLoading, setIsLoading] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
    const [filters, setFilters] = useState(initialFilterValues);
    const [selectedWorkspace, setSelectedWorkspace] = useState()
    const [isDashboard, setIsDashboard] = useState(false)
    const [workspaceId, setWorkspaceId] = useState()
    const [viewId, setViewId] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [isSyncZoho, setIsSyncZoho] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalItem, setModalItem] = useState();
    const [isBttonClicked, setIsButtonClicked] = useState(false);
    const [assignedCustomer, setAssignedCustomer] = useState()
    const [syncZohoMessage, setSyncZohoMessage] = useState()
    const [isWorkspaceSelected, setIsWorkspaceSelected] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    // Function to get all workspaces
    const getAllWorkspace = async () => {
        try {
            const response = await getWorkspaces(abortConstants.controllerConfig);
            console.log(response);
            setWorkspaces(response?.data?.WorkSpace);
            setIsWorkspaceSelected(false)
        } catch (error) {
            handleApiErrors(error, 'Workspaces', 'workspace_response', 'workspace_timeout', 'workspace_request', setIsLoading);
        }
    };

    // Function for deleting lead
    const deleteWorkspacedById = async (id) => {
        console.log(id);
        try {
            const result = await deleteWorkspace(id, abortConstants.controllerConfig);
            console.log(result);
            if (result) {
                setIsDeleted(false)
                successToast(result?.data?.message);
                setSelectedWorkspace(null)
                setSearchTerm('')
                setDashboards(null)
                setWorkspaces(null)
                setIsWorkspaceSelected(false)
                getAllWorkspace()
            }
        } catch (error) {
            setIsDeleted(false)
            handleApiErrors(error, 'Delete', 'deleteworkspace_response', 'deleteworkspace_timeout', 'deleteworkspace_request', setIsLoading);
        }
    };

    // Function to sync zoho workspaces
    const synZohoWorkspace = async () => {
        setIsSyncZoho(true);
        try {
            const response = await syncZoho(abortConstants.controllerConfig);
            if (response) {
                console.log(response);
                setIsButtonClicked(false)
                // alert(response?.data?.message)
                // setWorkspaces(response?.data?.WorkSpace);
                setSyncZohoMessage(response?.data?.message)
                setSelectedWorkspace(null)
                setSearchTerm('')
                setDashboards(null)
                setIsWorkspaceSelected(false)
                setWorkspaces(null)
                getAllWorkspace()
                setIsSyncZoho(false);
            }
        } catch (error) {
            setIsButtonClicked(false)
            setIsSyncZoho(false);
            handleApiErrors(error, 'Sync Zoho', 'sync_response', 'sync_timeout', 'sync_request', setIsLoading);
        }
    };

    // Function to get all leads
    const getAllDashboards = async (id) => {
        setIsWorkspaceSelected(true)
        try {
            const response = await getDashboards(id, abortConstants.controllerConfig);
            console.log(response);
            setDashboards(response?.data?.Dashboard);
            setAssignedCustomer(response?.data?.AssignWorkSpaces)
        } catch (error) {
            handleApiErrors(error, 'Get Dashboard', 'dashboard_response', 'dashboard_timeout', 'dashboard_request', setIsLoading);
        }
    };

    useEffect(() => {
        getAllWorkspace();
    }, []);

    // Function for deleting lead
    const deleteDashboardById = async (id) => {
        try {
            const result = await deleteDashboard(id, abortConstants.controllerConfig);
            if (result) {
                setIsDeleted(false)
                successToast(result?.data?.message);
                setDashboards(null)
                getAllDashboards(selectedWorkspace?.WorkSpaceId);
            }
        } catch (error) {
            setIsDeleted(false)
            handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
        }
    };

    // Function for updating lead
    const updateDashboardById = async (id, data) => {
        console.log(id, data);

        try {
            const result = await updateDashboard(id, data, abortConstants.controllerConfig);
            console.log(result);
            if (result) {
                successToast(result?.data?.message);
                setDashboards(null)
                getAllDashboards(selectedWorkspace?.WorkSpaceId);
            }
        } catch (error) {
            handleApiErrors(error, 'Update', 'update_response', 'update_timeout', 'update_request', setIsLoading);
        }
    };

    const handleFilterChange = (e, columnName) => {
        setFilters({
            ...filters,
            [columnName]: e.target.value
        });
    };

    const clearFilter = (columnName) => {
        setFilters({
            ...filters,
            [columnName]: ''
        });
    };

    const getFilteredLeads = () => {
        return dashboards?.filter(lead => {
            // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
            return (
                (filters.Name === '' || dashboards?.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
                // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
                // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
                // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
                // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
                (filters.Date === '' || convertDateForDisplay(dashboards.CreatedDate) === convertDateForDisplay(filters.Date)) &&
                // (filters.SourceName === '' || dashboards.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
                (filters.Status === '' || dashboards?.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
            );
        });
    };

    // Get current leads
    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    // const currentLeads = getFilteredLeads().slice(indexOfFirstLead, indexOfLastLead);

    // Change page
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(dashboards.length / leadsPerPage)));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleWorkspaceClick = (workspace) => {
        console.log('Selected suggestion:', workspace); // Access both id and name
        setDashboards(null)
        getAllDashboards(workspace?.WorkSpaceId)
    };

    // const refresh = () => {
    //     setWorkspaces(null)
    //     getAllWorkspace()
    // }

    const handleModal = (item) => {
        if (item === "assign" || item === "set_order") {
            if (!selectedWorkspace) {
                // Show a message if no workspace is selected
                toast.error("Please select a workspace first.", {
                    autoClose: 5000
                })
                return;
            }
        }
        setModalItem(item);
        setModalShow(true);
    }

    const handleWorkspaceDelete = () => {
        if (!selectedWorkspace) {
            // Show a message if no workspace is selected
            toast.error("Please select a workspace first.", {
                autoClose: 5000
            })
        } else {
            handleDeleteConfirmation(selectedWorkspace?.WorkSpaceId, 'workspace')
        }
    }
    const handleDeleteConfirmation = (id, type) => {
        // handleCloseMenu();
        const confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation) {
            setIsDeleted(true)
            if (type === "dashboard") {
                deleteDashboardById(id)
            } else {
                deleteWorkspacedById(id)
            }
        }
    };

    const updateIsDefault = (id, isDefault, isActive) => {
        const data = {
            "IsDefault": isDefault === 1 ? 0 : 1,
            "Isactive": isActive
        }
        updateDashboardById(id, data)
    }

    const updateIsActive = (id, isDefault, isActive) => {
        const data = {
            "IsDefault": isDefault,
            "Isactive": isActive === 1 ? 0 : 1
        }
        updateDashboardById(id, data)
    }

    const modalClose = () => {
        setSyncZohoMessage(null)
        setModalShow(false);
        // if(isBttonClicked){
        //     refresh()
        // }
    }

    const handleOrder = () => {
        setDashboards(null)
        getAllDashboards(selectedWorkspace?.WorkSpaceId)
    }

    //for loading circular progress
    const renderLoading = (item) => {
        if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <>{!isDashboard ?
            <div>
                <Row>
                    <Col xs={6}>
                        <h5>Workspace Management</h5>
                    </Col>
                    {/* <Col xs={6} className="d-flex justify-content-end">
          <CrudActions crudNo={1} form={LeadForm} title="New Lead" isEdit={false} getData={getAllLeads} />
        </Col> */}
                </Row>
                <Row className="mt-3">
                    <Col xs={12}>
                        {workspaces ? workspaces?.length > 0 ? (
                            <Row>
                                <Col xs={12} md={8}>
                                    <AutoSearch title="Search workspace" suggestions={workspaces}
                                        keyName='WorkSapceName'
                                        handleItemClick={handleWorkspaceClick}
                                        selectedItem={selectedWorkspace}
                                        setSelectedItem={setSelectedWorkspace}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm} />
                                </Col>
                                <Col xs={12} md={4} className='d-flex align-items-center'>
                                    <Dropdown >
                                        <Dropdown.Toggle
                                            as="span"
                                            style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
                                            id="dropdown-custom-components"
                                        >
                                            <TbDotsVertical />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleModal('assign')}>Assign Customer</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleWorkspaceDelete()}>Delete Workspace</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleModal('set_order')}>Set Order for Dashboard</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <span className='ms-4 common-icon' onClick={() => handleModal('sync')}><IoSettingsOutline /></span>
                                    <Modal
                                        show={modalShow}
                                        // onHide={() => setModalShow(false)}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header >
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                {modalItem === 'sync' && <span>Settings</span>}
                                                {modalItem === 'assign' && <span>Assign Customer</span>}
                                                {modalItem === 'set_order' && <span>Set Order for Dashboard</span>}
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {modalItem === 'sync' && <div>
                                                {isSyncZoho ?
                                                    <div className="d-flex justify-content-center mt-1">
                                                        <Spinner animation="border" />
                                                    </div>
                                                    :
                                                    <>{!syncZohoMessage &&
                                                        <span>
                                                            If the workspace is not synced yet, &nbsp;&nbsp;<Link onClick={() => { synZohoWorkspace(); setIsButtonClicked(true) }}>Please Click here</Link>
                                                        </span>}
                                                        {syncZohoMessage && <span>{syncZohoMessage}</span>}
                                                    </>

                                                }
                                            </div>}

                                            {modalItem === 'assign' && <div>
                                                <AssignCustomer workspace={selectedWorkspace} closeModal={setModalShow} getData={getAllDashboards} assignedCustomer={assignedCustomer} />
                                            </div>}

                                            {modalItem === 'set_order' && <div>
                                                <SetOrder dashboards={dashboards} closeModal={setModalShow} handleData={handleOrder} />
                                            </div>}
                                        </Modal.Body>
                                        {modalItem === 'sync' && <Modal.Footer>
                                            <Button onClick={() => modalClose()} disabled={isBttonClicked}>Close</Button>
                                        </Modal.Footer>}
                                    </Modal>
                                    {/* <Dropdown className='ms-3'>
                                        <Dropdown.Toggle
                                            as="span"
                                            style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
                                            id="dropdown-custom-components"
                                        >
                                            <IoSettingsOutline />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => synvZohoWorkspace()}>Sync WorkSpace from Zoho</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    {/* <Button className='button save' onClick={() => synvZohoWorkspace()}>Sync Zoho</Button> */}
                                </Col>
                                <Col xs={12}>
                                    {assignedCustomer && assignedCustomer.length !== 0 && <span className='workspace subtitle-span'>Workspace assigned to {(assignedCustomer.map(item => item.CustomerName)).join(',')}</span>}
                                </Col>
                                {dashboards ? <>
                                    {!isDeleted ?
                                        <>   {dashboards?.length !== 0 &&
                                            <Col xs={12} className='mt-3'>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th className="table-style table-heading">Dashboard
                                                                <Filter columnName="Name" handleFilterChange={handleFilterChange} />
                                                            </th>
                                                            <th className="table-style table-heading">Workspace Name</th>
                                                            <th className="table-style table-heading">Is Default</th>
                                                            <th className="table-style table-heading">Status</th>
                                                            <th className="table-style table-heading">Last Modified</th>
                                                            <th className="table-style table-heading"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dashboards?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="table-style table-font">{item.name}</td>
                                                                <td className="table-style table-font">{item.dbName}</td>
                                                                <td className="table-style table-font">{item.isDefault === 1 ? 'yes' : 'No'}</td>
                                                                <td className="table-style table-font">{item.isactive === 1 ? 'Active' : 'Disabled'}</td>
                                                                <td className="table-style table-font">{item.modifiedTime}</td>
                                                                <td className="table-style table-font">
                                                                    <Dropdown >
                                                                        <Dropdown.Toggle
                                                                            as="span"
                                                                            style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}
                                                                            id="dropdown-custom-components"
                                                                        >
                                                                            <TbDotsVertical />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => { setWorkspaceId(item.dbId); setViewId(item.id); setIsDashboard(true) }}
                                                                            >View Dashboard</Dropdown.Item>
                                                                            {item.isDefault !== 1 && <Dropdown.Item onClick={() => updateIsDefault(item.id, item.isDefault, item.isactive)}>Set as Default</Dropdown.Item>}
                                                                            <Dropdown.Item onClick={() => handleDeleteConfirmation(item.id, "dashboard")}>Delete Dashboard</Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => updateIsActive(item.id, item.isDefault, item.isactive)}>{item.isactive === 1 ? 'Disable Dashboard' : 'Active Dashboard'}</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <Pagination className="justify-content-end">
                                                    <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                                    <Pagination.Item>{currentPage}</Pagination.Item>
                                                    <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(dashboards.length / leadsPerPage)} />
                                                </Pagination>
                                            </Col>
                                        }</> : <>
                                            <div className="d-flex justify-content-center mt-5">
                                                <Spinner animation="border" />
                                            </div>
                                        </>}
                                </> :
                                    <>
                                        {isWorkspaceSelected && (
                                            renderLoading('dashboard') ?
                                                <h5 style={{ textAlign: 'center' }}>Error Occured</h5> :
                                                (<div className="d-flex justify-content-center mt-5">
                                                    <Spinner animation="border" />
                                                </div>
                                                )
                                        )}
                                    </>
                                }
                            </Row>
                        ) : (
                            <span style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                                {isSyncZoho ?
                                    <div className="d-flex justify-content-center mt-1">
                                        <Spinner animation="border" />
                                    </div>
                                    :
                                    <>{!syncZohoMessage &&
                                        <span>
                                            If the workspace is not synced yet, &nbsp;&nbsp;<Link onClick={() => synZohoWorkspace()}>Please Click here</Link>
                                        </span>}
                                        {syncZohoMessage && <span>{syncZohoMessage}</span>}
                                    </>

                                }
                            </span>
                        ) :
                            <>
                                {renderLoading('workspace') ?
                                    <h5 style={{ textAlign: 'center' }}>Error Occured</h5> :
                                    (<div className="d-flex justify-content-center mt-5">
                                        <Spinner animation="border" />
                                    </div>)}
                            </>
                        }
                    </Col>
                </Row>
            </div> :
            <Dashboard worksapceid={workspaceId} viewid={viewId} setIsDashboard={setIsDashboard} setViewId={setViewId} setWorkspaceId={setWorkspaceId} />
        }</>
    );
}

export default Workspace;


// {renderLoading('ledger') ?
//     <h5 style={{ textAlign: 'center' }}>No data</h5> :
//     (<Box sx={{ textAlign: 'center', marginTop: '20px' }}>
//         <CircularProgress />
//     </Box>)
// }