import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Accordion, InputGroup, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { convertLead, createLead, createLeadNote, deleteLeadNote, getIndustry, getLeadById, getLeadNoteByCriteria, getPlans, getstatus, subDomainValidity, updateLead, updateLeadNote } from '../../api/leadsApi'
import { getUsers } from '../../api/userApi'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IoIosTime } from "react-icons/io";
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'

const validationRules = {
    Name: { required: true },
    CompanyName: { required: true },
    City: { required: true },
    ZipCode: { required: true },
    Street: { required: true },
    TaxNo: { required: true },
    SubDomain: { required: true, isSubDomain: true },
    PlanId: { required: true },
    UserName: { required: true },
    Password: { required: true }
    // Duration: { required: true }
};

function ConvertForm({ id, leadsData, closeModal, setIsConvert, getData }) {
    const abortConstants = useSignalAbort()

    useEffect(() => {
        const updatedData = { ...leadsData, SubDomain: leadsData?.SubDomain?.split('.')[0] }
        setLeadData(updatedData)
    }, [leadsData])

    const user = useSelector(state => state?.auth?.adminUser)
    const initialValues = {
        ZipCode: "",
        City: "",
        Street: "",
        TaxNo: "",
        SubDomain: "",
        ConvertedBy: user.userid,
        Duration: "",
        PlanId: '',
        UserName: "",
        Password: ""
    }
    const [leadData, setLeadData] = useState()
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [planSelect, setPlanSelect] = useState('')
    const [planList, setPlanList] = useState()
    const [selectedPlan, setSelectedPlan] = useState()
    const [isLoading, setIsLoading] = useState({})

    useEffect(() => {
        getLeadPlans(abortConstants.controllerConfig)
    }, [])

    //For form validation
    //   const validate = (values) => {
    //     const errors = validateForm(values, validationRules);
    //     console.log(errors);
    //     setFormerrors(errors)
    //     if (Object.keys(errors).length === 0) {
    //       return true;
    //     }
    //     else {
    //       return false;
    //     }
    //   }

    //for getting plans
    const getLeadPlans = async (abortToken) => {
        try {
            const result = await getPlans(abortToken)
            setPlanList(result?.data?.SubscriptionPlan)
        }
        catch (error) {
            handleApiErrors(error, 'Plans', 'plan_response', 'plan_timeout', 'plan_request', setIsLoading)
        }
    }
    //for updating lead
    const updateLead = async (leadId, newData, abortToken) => {
        console.log(newData);
        try {
            const result = await convertLead(leadId, newData, abortToken)
            console.log(leadId, result);
            if (result) {
                successToast(result?.data?.message)
                setIsSaving(false)
                closeModal()
                setLeadData(initialValues)
                setFormerrors({})
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Lead', 'editlead_response', 'editlead_timeout', 'editlead_request', setIsLoading)
        }
    }

    //for updating lead
    const subDomainValid = async (domain, newData, abortToken) => {
        try {
            const result = await subDomainValidity(domain, abortToken)
            console.log(result);
            if (result?.data?.Result === 0) {
                setIsSaving(false)
                setFormerrors({ isAvailable: "Domain not available." })
            } else {
                updateLead(id, newData, abortConstants.controllerConfig)
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Checking Domain', 'domain_response', 'domain_timeout', 'domain_request', setIsLoading)
        }
    }

    const handlePlanChange = (e) => {
        const plan = planList.find(item => item.PlanId === Number(e.target.value))
        setPlanSelect(e.target.value)
        setSelectedPlan(plan)
    }

    //To set input values for add branch 
    const handleChange = (e) => {
        setLeadData({ ...leadData, [e.target.name]: e.target.value })
    }

    //To genarate random password
    const generatePassword = () => {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };

    const handleGeneratePassword = () => {
        const generatedPassword = generatePassword();
        setLeadData({ ...leadData, Password: generatedPassword });
    };

    //For form validation
    const validate = (values) => {
        const errors = validateForm(values, validationRules);
        console.log(errors);
        setFormerrors(errors)
        if (Object.keys(errors).length === 0) {
            return true;
        }
        else {
            setIsSaving(false)
            return false;
        }
    }
    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setFormerrors({})
        let newData = {
            ...leadData,
            SubDomain: `${leadData.SubDomain}.rcorintelliger.co`,
            PlanId: Number(planSelect),
            Duration: Number(leadData.Duration)
        }
        console.log(newData);
        if (validate({ ...leadData, PlanId: Number(planSelect) })) {
            subDomainValid(newData.SubDomain, newData, abortConstants.controllerConfig)
        } else {
            setIsSaving(false)
        }
        setValidated(true)
    }

    return (
        <div >
            <Row className='d-flex justify-content-end mb-2'>
                <Col xs={12} className='d-flex justify-content-between align-items-center'>
                    <span className='modal-title'>Lead Convert</span>
                    <Button className='button save' onClick={() => setIsConvert(false)}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit} >
                <Row>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Customer Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control readOnly size='sm' type='type' name='Name' value={leadsData?.Name} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Company<span className='asterik'> *</span></Form.Label>
                            <Form.Control readOnly size='sm' type='type' name='CompanyName' value={leadsData?.CompanyName} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={6}>
                        <Form.Group controlId='phone'>
                            <Form.Label className='text-left label form-label' >City<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={25} required size='sm' type='type' name='City' value={leadData?.City} onChange={handleChange} />
                            {/* <span className='form-error text'>
                                {formErrors?.City}
                            </span> */}
                            <Form.Control.Feedback type="invalid">{formErrors?.City}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Zipcode<span className='asterik'> *</span></Form.Label>
                            <Form.Control
                                maxLength={7}
                                // pattern="[0-9]*"
                                required
                                size='sm'
                                type='type'
                                name='ZipCode'
                                value={leadData?.ZipCode}
                                onChange={handleChange}
                            // isInvalid={!!formErrors?.ZipCode} 
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.ZipCode}</Form.Control.Feedback>
                            {/* <span className='form-error text'>
                                {formErrors?.ZipCode}
                            </span> */}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Street<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={25} required size='sm' type='type' name='Street' value={leadData?.Street} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Street}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='tax'>
                            <Form.Label className='text-left label form-label' >TRN/GST No<span className='asterik'> *</span></Form.Label>
                            <Form.Control required maxLength={25} size='sm' type='type' name='TaxNo' value={leadData?.TaxNo} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.TaxNo}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='plan'>
                            <Form.Label className='text-left label form-label' >Plan<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={planSelect} onChange={handlePlanChange}>
                                <option value="" disabled>Select</option>
                                {planList?.map(item => (
                                    <option key={item.PlanId} value={item.PlanId}>{item.Name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.PlanId ? "Plan is required" : ''}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='days'>
                            <Form.Label className='text-left label form-label' >Duration<span className='asterik'> *</span></Form.Label>
                            <InputGroup>
                                <Form.Control min={1} required maxLength={5} size='sm' type='number' name='Duration' value={leadData?.Duration} onChange={handleChange} />
                                <InputGroup.Text>{selectedPlan?.BillingCycle}</InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">{formErrors?.Duration}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Username<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='type' name='UserName' value={leadData?.UserName} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.UserName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        {/* <Row style={{border:'1px solid'}}>
                            <Col xs={12} md={9}>
                                <Form.Group controlId='company'>
                                    <Form.Label className='text-left label form-label' >Password<span className='asterik'> *</span></Form.Label>
                                    <Form.Control required size='sm' type='type' name='Passoword' value={leadsData?.Passoword} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{formErrors?.Passoword ? "Password is required" : ''}</Form.Control.Feedback>
                                </Form.Group></Col>
                            <Col xs={12} md={3}>
                                <Button variant="secondary" size="sm" onClick={handleGeneratePassword}>Generate</Button>
                            </Col>
                        </Row> */}
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Password<span className='asterik'> *</span></Form.Label>
                            <Row>
                                <Col xs={9} >
                                    <Form.Control required size='sm' type='type' name='Password' value={leadData?.Password} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{formErrors?.Password}</Form.Control.Feedback>
                                </Col>
                                <Col xs={3} >
                                        <Button variant="secondary" size="sm" onClick={handleGeneratePassword}>Generate</Button>
                                </Col>
                            </Row>

                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={8}>
                        <Form.Group controlId='domain'>
                            <Form.Label className='text-left label form-label' >Sub Domain Name<span className='asterik'> *</span></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    maxLength={15}
                                    required
                                    size='sm'
                                    type='text'
                                    name='SubDomain'
                                    value={leadData?.SubDomain}
                                    onChange={handleChange}
                                    isInvalid={!!formErrors?.isAvailable || formErrors?.SubDomain} />
                                <InputGroup.Text>.rcorintelliger.co</InputGroup.Text>
                            </InputGroup>
                            <span className='form-error text'>
                                {formErrors?.SubDomain}
                                {formErrors?.isAvailable}
                            </span>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ConvertForm