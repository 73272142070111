import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import CrudActions from '../../components/CrudActions';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { deleteLead, getLead } from '../../api/leadsApi';
import { useSelector } from 'react-redux';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';
import { getUsers, getUserType } from '../../api/userApi';
import RoleForm from './RoleForm';

const initialFilterValues ={
  Name: '',
  // CompanyName: '',
  // IndustryName: '',
  // PhoneNumber: '',
  // EmailId: '',
  Date: '',
  // SourceName: '',
  Status: ''
}
function Roles() {
  const abortConstants = useSignalAbort();
  const user = useSelector(state => state.adminUser);
  const menu = useSelector(state => state.userMenu);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
  const [filters, setFilters] = useState(initialFilterValues);

  // Function to get all leads
  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const response = await getUserType(abortConstants.controllerConfig);
      console.log(response);
      setUsers(response?.data?.UserType);
      setIsLoading(false);
    } catch (error) {
      handleApiErrors(error, 'Users', 'user_response', 'user_timeout', 'user_request', setIsLoading);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // Function for deleting lead
  const deleteLeads = async (id) => {
    try {
      const result = await deleteLead(id, abortConstants.controllerConfig);
      if (result) {
        successToast(result?.data?.message);
        getAllUsers();
      }
    } catch (error) {
      handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
    }
  };

  const handleFilterChange = (e, columnName) => {
    setFilters({
      ...filters,
      [columnName]: e.target.value
    });
  };

  const clearFilter = (columnName) => {
    setFilters({
      ...filters,
      [columnName]: ''
    });
  };

  // const getFilteredLeads = () => {
  //   return leads.filter(lead => {
  //     // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
  //     return (
  //       (filters.Name === '' || lead.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
  //       // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
  //       // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
  //       // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
  //       // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
  //       (filters.Date === '' || convertDateForDisplay(lead.CreatedDate) === convertDateForDisplay(filters.Date)) &&
  //       // (filters.SourceName === '' || lead.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
  //       (filters.Status === '' || lead.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
  //     );
  //   });
  // };

  // Get current leads
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = users.slice(indexOfFirstLead, indexOfLastLead);

  // console.log(leads.filter(item =>convertDateForDisplay(item.CreatedDate) === '15-05-2024'));
  // Change page
  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(users.length / leadsPerPage)));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <div>
      <Row>
        <Col xs={6}>
          <h5>Role Management</h5>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <CrudActions crudNo={1} form={RoleForm} title="New Lead" isEdit={false} getData={getAllUsers} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" />
            </div>
          ) : users.length > 0 ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="table-style table-heading">Role
                    {/* <Filter columnName="Name" handleFilterChange={handleFilterChange} /> */}
                    </th>
                    <th className="table-style table-heading">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads.map((item, index) => (
                    <tr key={index}>
                      <td className="table-style table-font">{item.usertypename}</td>
                      <td className="table-style table-font">
                        <CrudActions id={item.LeadsId} crudNo={2} form={RoleForm} title="Edit Lead" isEdit={true} getData={getAllUsers} />
                        <CrudActions id={item.LeadsId} crudNo={5} getData={getAllUsers} deleteData={deleteLeads} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="justify-content-end">
                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(users.length / leadsPerPage)} />
              </Pagination>
            </>
          ) : (
            <h4 style={{ textAlign: 'center' }}>No data</h4>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Roles;
