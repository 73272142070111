import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner, InputGroup, Dropdown } from 'react-bootstrap'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { createLead, getIndustry, getLeadById, getstatus, updateLead } from '../../api/leadsApi'
import { getUsers } from '../../api/userApi'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { getCountry } from '../../../api/countryApi'

function UserForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort()
    const initialValues = {
        Name: "",
        PhoneNumber: "",
        CompanyName: "",
        Description: "",
        CompanySize: "",
        EmailId: "",
        PreferredDate: "",
        Website: "",
        AnnualRevenue: null,
    }

    const validationRules = {
        Name: { required: true },
        CompanyName: { required: true },
        Description: { required: true },
        CompanySize: { required: true },
        EmailId: { required: true, isEmail: true },
        PreferredDate: { required: true },
        PhoneNumber: { isPhoneNumber: true, required: true },
        Website: { required: true, isURL: true },
        AnnualRevenue: { isNumber: true },
        SourceId: { required: true },
        IndustryId: { required: true },
        LeadOwner: { required: true },
        Status: { required: true },
        CountryId: { required: true }
    };

    const [leadData, setLeadData] = useState(initialValues)
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [sourceSelect, setSourceSelect] = useState("")
    const [industrySelect, setIndustrySelect] = useState("");
    const [statusSelect, setStatusSelect] = useState("");
    const [userSelect, setUserSelect] = useState("");
    const [userList, setUserList] = useState()
    const [statusList, setStatusList] = useState()
    const [sourceList, setSourceList] = useState()
    const [industryList, setIndustryList] = useState()
    const [countryList, setCountryList] = useState()
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isLoading, setIsLoading] = useState({})

    //function to get list of sources
    const getSource = async () => {
        try {
            const result = await getSources(abortConstants.controllerConfig)
            setSourceList(result?.data?.Source)
            // setSourceSelect(result?.data?.Source[0]?.SourceId)
        }
        catch (error) {
            handleApiErrors(error, 'Source', 'source_response', 'source_timeout', 'source_request', setIsLoading)
        }
    }

    //function to get list of industries
    const getAllIndustry = async () => {
        try {
            const result = await getIndustry(abortConstants.controllerConfig)
            setIndustryList(result?.data?.Industry)
            // setIndustrySelect(result?.data?.Industry[0]?.IndustryId)
        }
        catch (error) {
            handleApiErrors(error, 'Industry', 'industry_response', 'industry_timeout', 'industry_request', setIsLoading)
        }
    }

    //function to get list of status
    const getAllStatus = async () => {
        try {
            const result = await getstatus(abortConstants.controllerConfig)
            setStatusList(result?.data?.LeadStatus)
            // setStatusSelect(result?.data?.LeadStatus[0]?.Id)
        }
        catch (error) {
            handleApiErrors(error, 'Status', 'status_response', 'status_timeout', 'status_request', setIsLoading)
        }
    }

    //function to get list of owners
    const getAllOwners = async () => {
        try {
            const result = await getUsers(abortConstants.controllerConfig)
            setUserList(result?.data?.User)
            console.log(result);
            // setUserSelect(result?.data?.User[0]?.userid)
        }
        catch (error) {
            handleApiErrors(error, 'Owners', 'owners_response', 'owners_timeout', 'owners_request', setIsLoading)
        }
    }

    //function to get country 
    const getCountryData = async () => {
        try {
            const result = await getCountry(abortConstants.controllerConfig);
            console.log(result?.data?.Country);
            setCountryList(result?.data?.Country);
            setSelectedCountry(result?.data?.Country[0])
        } catch (error) {
            handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
        }
    };

    useEffect(() => {
        getSource()
        getAllIndustry()
        getAllStatus()
        getAllOwners()
        getCountryData()
    }, [])

    useEffect(() => {
        if (isEdit) {
            const getLead = async () => {
                try {
                    const result = await getLeadById(id, abortConstants.controllerConfig)
                    console.log(result);
                    const lead = result?.data?.Leads
                    const countrySelected = countryList?.find(item => item.countryId === lead?.CountryId)
                    let regex = new RegExp(`^\\+?${countrySelected?.CallingCode}`);
                    setSelectedCountry(countrySelected)
                   if(countrySelected){
                    setLeadData({
                        ...lead,
                        // Name: lead.Name,
                        PhoneNumber: lead.PhoneNumber.replace(regex, ''),
                        // CompanyName: lead.CompanyName,
                        // Description: lead.Description,
                        // CompanySize: lead.CompanySize,
                        // EmailId: lead.EmailId,
                        PreferredDate: lead.PreferredDate ?changeFormat(dayjs(lead.PreferredDate), 'yyyy-mm-dd'):'',
                        // Website: lead.Website,
                        // AnnualRevenue: lead.AnnualRevenue,
                    });
                   }
                    setSourceSelect(lead.SourceId)
                    setIndustrySelect(lead.IndustryId)
                    setStatusSelect(lead.Status)
                    setUserSelect(lead.LeadOwner === 0? '' : lead.LeadOwner)
                }
                catch (error) {
                    handleApiErrors(error, 'Get Lead', 'getlead_response', 'getlead_timeout', 'getlead_request', setIsLoading)
                }
            }
            getLead()
        }
    }, [id, countryList])

    //for creating lead
    const createLeads = async (data, abortToken) => {
        console.log(data);
        try {
            const result = await createLead(data, abortToken)
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Add Lead', 'addlead_response', 'addlead_timeout', 'addlead_request', setIsLoading)
        }
    }

    //for updating lead
    const updateLeads = async (id, data, abortToken) => {
        console.log(data);
        try {
            const result = await updateLead(id, data, abortToken)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Lead', 'editlead_response', 'editlead_timeout', 'editlead_request', setIsLoading)
        }
    }

    //To set input values for form
    const handleChange = (e) => {
        // setFormerrors({})
        setLeadData({ ...leadData, [e.target.name]: e.target.value },)
    }

    //For form validation
    const validate = (values) => {
        const errors = validateForm(values, validationRules);
        console.log(errors);
        setFormerrors(errors)
        if (Object.keys(errors).length === 0) {
            return true;
        }
        else {
            setIsSaving(false)
            return false;
        }
    }

    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setFormerrors({})
        console.log(selectedCountry?.countryId);
        const data = {
            ...leadData,
            SourceId: Number(sourceSelect),
            IndustryId: Number(industrySelect),
            LeadOwner: Number(userSelect),
            Status: Number(statusSelect),
            AnnualRevenue: Number(leadData.AnnualRevenue),
            CountryId: Number(selectedCountry?.countryId),
        }
        console.log(data);
        if (validate(data)) {
            if (isEdit) {
                updateLeads(id, { ...data, PhoneNumber: "+" + selectedCountry?.CallingCode + leadData.PhoneNumber }, abortConstants.controllerConfig)
            } else {
                createLeads({ ...data, PhoneNumber: "+" + selectedCountry?.CallingCode + leadData.PhoneNumber }, abortConstants.controllerConfig)
            }
        }
        setValidated(true)
    }


    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={100} required size='sm' type='type' name='Name' value={leadData.Name} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Name}</Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='phone'>
                            <Form.Label className='text-left label form-label'>Phone<span className='asterik'> *</span></Form.Label>
                            <InputGroup size='sm'>
                                <Dropdown >
                                    <Dropdown.Toggle size='sm' id="dropdown-basic" style={{ backgroundColor: '#F6F6F6', color: 'black', border: '1px solid #c4c3c3' }}>
                                        {selectedCountry && <span>
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                                                alt={selectedCountry.countryName}
                                                className="country-flag"
                                                style={{ marginRight: '5px' }}
                                            />
                                            +{selectedCountry.CallingCode}
                                        </span>}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {countryList?.map(item => (
                                            <Dropdown.Item key={item?.countryId} onClick={() => setSelectedCountry(item)}>
                                                <img
                                                    src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                                                    alt={item.countryName}
                                                    className="country-flag"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                +{item?.CallingCode} ({item?.countryName})
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                    maxLength={selectedCountry?.MobileNumberlen}
                                    pattern="[0-9]*"
                                    required
                                    size='sm'
                                    type='tel'
                                    name='PhoneNumber'
                                    value={leadData.PhoneNumber}
                                    onChange={handleChange}
                                    // placeholder="Phone Number"
                                    aria-label="Phone number"
                                />
                            </InputGroup>
                            <Form.Control.Feedback type="invalid" className="d-block mt-1">
                                {formErrors?.PhoneNumber} {formErrors?.CountryId}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Company Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={120} required size='sm' type='type' name='CompanyName' value={leadData.CompanyName} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.CompanyName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='email'>
                            <Form.Label className='text-left label form-label' >Email ID<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='email' name='EmailId' value={leadData.EmailId} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.EmailId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="designation">
                            <Form.Label className='text-left label form-label' >Designation<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={sourceSelect} onChange={(e) => setSourceSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {sourceList?.map(item => (
                                    <option key={item.SourceId} value={item.SourceId}>{item.SourceName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="role">
                            <Form.Label className='text-left label form-label' >Role<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={industrySelect} onChange={(e) => setIndustrySelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {industryList?.map(item => (
                                    <option key={item.IndustryId} value={item.IndustryId}>{item.IndustryName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='date'>
                            <Form.Label className='text-left label form-label' >Preferred Date<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='date' name='PreferredDate' value={leadData.PreferredDate} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.PreferredDate}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="source">
                            <Form.Label className='text-left label form-label' >Source<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={sourceSelect} onChange={(e) => setSourceSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {sourceList?.map(item => (
                                    <option key={item.SourceId} value={item.SourceId}>{item.SourceName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="industry">
                            <Form.Label className='text-left label form-label' >Industry<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={industrySelect} onChange={(e) => setIndustrySelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {industryList?.map(item => (
                                    <option key={item.IndustryId} value={item.IndustryId}>{item.IndustryName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row> */}
                {/* <Row className='mt-1'>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='revenue'>
                            <Form.Label className='text-left label form-label' >Annual Revenue<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={20} size='sm' pattern="[0-9]*" type='number' name='AnnualRevenue' value={leadData.AnnualRevenue} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.AnnualRevenue}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="status">
                            <Form.Label className='text-left label form-label' >Lead Status<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={statusSelect} onChange={(e) => setStatusSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {statusList?.map(item => (
                                    <option key={item.Id} value={item.Id}>{item.Name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.Status}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='reason'>
                            <Form.Label className='text-left label form-label' >Reason</Form.Label>
                            <Form.Control size='sm' type='type' name='Reason' />
                        </Form.Group>
                    </Col>
                </Row> */}
                {/* <Row className='mt-1'>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="owner">
                            <Form.Label className='text-left label form-label' >Lead Owner<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={userSelect} onChange={(e) => setUserSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {userList?.map(item => (
                                    <option key={item.userid} value={item.userid}>{item.firstname}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.LeadOwner}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Group className='description'>
                            <Form.Label className='text-left label form-label'>Description<span className='asterik'> *</span></Form.Label>
                            <Form.Control size='sm' className='text-left' required type='text' name='Description' value={leadData.Description} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Description}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row> */}
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    // disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default UserForm