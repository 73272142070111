import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer and styles
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Home from './pages/home/Home';
import Features from './pages/features/Features';
import Pricing from './pages/pricing/Pricing';
import CaseStudy from './pages/casestudy/CaseStudy';
import Resources from './pages/resources/Resources';
import Contact from './pages/contact/Contact';
import Demo from './components/home/Demo';
import Feedback from './components/home/Feedback';
import Leads from './AdminPanel/Pages/leads/Leads';
import Login from './AdminPanel/Pages/Login'
import Contacts from './AdminPanel/Pages/contact/Contacts';
import Feedbacks from './AdminPanel/Pages/feedback/Feedbacks';
import User from './AdminPanel/Pages/user/User';
import MainPage from './AdminPanel/Pages/MainPage';
import MainHome from './AdminPanel/Pages/MainHome';
import SessionExpired from './AdminPanel/Pages/SessionExpired';
import Customer from './AdminPanel/Pages/customer/Customer';
import { useSignalAbort } from './api/useSignalAbort';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDefaultCountry } from './api/geoLocationApi';
import { handleApiErrors } from './handleApiErrors';
import { setGeoData } from './redux/slices/geoSlice';
import PageNotFound from './pages/PageNotFound';
import PrivateRoute from './AdminPanel/components/PrivateRoute';
import Blog from './pages/blog/Blog';
import BlogReadmore from './pages/blog/BlogReadmore';
import AdminBlog from './AdminPanel/Pages/blog/AdminBlog';
import ReadmoreBlog from './pages/blog/ReadmoreBlog';
import PromoCode from './AdminPanel/Pages/discount/PromoCode';
import InvalidDomain from './pages/InvalidDomain';
import About from './pages/about/About';
import Workspace from './AdminPanel/Pages/workspace/Workspace';
import Roles from './AdminPanel/Pages/roles/Roles';
import Dashboard from './AdminPanel/Pages/workspace/Dashboard';
 
function App() {
  const abortConstants = useSignalAbort()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState({})
 
  const setDefaultCountry = async () => {
    try {
        const response = await getDefaultCountry(abortConstants.controllerConfig)
        if (response.data?.Country) {
            dispatch(setGeoData({ geoData: response?.data?.Country}))
        }
    }
    catch (error) {
        handleApiErrors(error, 'Default Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
    }
}
 
useEffect(()=>{
  setDefaultCountry()
}, [])
 
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Demo />} />
          <Route path='/about' element={<About />} />
          <Route path='/features' element={<Features />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/casestudy' element={<CaseStudy />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/ReadMore' element={<ReadmoreBlog />} />
          <Route path='/ReadMore/:id' element={<BlogReadmore />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Demo' element={<Demo />} />
          <Route path='/Feedback' element={<Feedback />} />
 
          {/* Routes for admin panel */}
          <Route path="/Login" element={<Login />} />
          <Route element={<MainPage />}>
            <Route path="/user_management" element={<PrivateRoute><User /></PrivateRoute>} />
            <Route path="/Roles" element={<Roles/>} />
            <Route path="/Leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
            <Route path="/ContactUs" element={<PrivateRoute><Contacts /></PrivateRoute>} />
            <Route path="/FeedbackView" element={<PrivateRoute><Feedbacks /></PrivateRoute>} />
            <Route path="/Customers" element={<PrivateRoute><Customer /></PrivateRoute>} />
            <Route path="/Blogs" element={<AdminBlog/>} />
            <Route path="/Discount" element={<PromoCode/>} />
            <Route path="/WorkSpaces" element={<Workspace/>} />
            <Route path="/view_dashboard" element={<Dashboard/>} />
          </Route>
          <Route path ='/Admin_Home' element={<MainHome/>}/>
          <Route path ='/logout' element={<SessionExpired/>}/>
          <Route path ='*' element={<PageNotFound/>}/>
          <Route path ='/InvalidURl' element={<InvalidDomain/>}/>
        </Routes>
      </BrowserRouter>
 
      <ToastContainer
        position="top-right"
        autoClose={5000}
      />
    </div>
  );
}
 
export default App;